/*
 * @Date: 2023-02-12 13:09:13
 * @LastEditors: simLarry
 * @LastEditTime: 2023-03-14 16:04:50
 * @FilePath: \F1-M1-QRP-CODE\actions\API.js
 */
import HostConfig from '$CONFIG/Host.config'
export const CMSURL = HostConfig.Config.CMSURL;
export const HostApi = HostConfig.Config.HostApi;
export const QRCodeURl = HostConfig.Config.QRCODEURL ;
export const BFFSC_URL =  HostConfig.Config.BFFSC_URL ;

export const ApiPort = {
	// banner 图
	Banner1 : CMSURL + `/cms/qrp-m1-001-banners`,
	Banner2 : CMSURL + `/cms/qrp-m1-002-banners`,
	Banner3 : CMSURL + `/cms/qrp-m1-003-banners`,
	Banner4 : CMSURL + `/cms/qrp-m1-004-banners`,
	Banner5 : CMSURL + `/cms/qrp-m1-005-banners`,
	Banner6 : CMSURL + `/cms/qrp-m1-006-banners`,
	Banner7 : CMSURL + `/cms/qrp-m1-007-banners`,
	Banner8 : CMSURL + `/cms/qrp-m1-008-banners`,
	Banner9 : CMSURL + `/cms/qrp-m1-009-banners`,

	// 注册API
	Register: HostApi + `/api/QRP/Register`,
	// 登录
	Login : HostApi + `/api/QRP/Login` ,
	// 
	QrpLoad : HostApi + '/api/QRP/URLs' ,

	// APP 下载 二维码
	Qrcode : QRCodeURl + '/thirdparty-payment/api/v1.0/qr/qr-code',

	AffiliateLink : HostApi + `/api/QRP/AffiliateLink` ,
	CaptchaInfo : BFFSC_URL + '/api/Verification/Captcha/Info',
	CaptchaChallenge: BFFSC_URL + '/api/Verification/Captcha/ChallengeId'
    

}